import styled from 'styled-components'

export const ProductRegion = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1350px) {
    max-width: 311px;
  }
`

export const ProductImageContainer = styled.div`
  width: 311px;
  height: 216px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const ProductImage = styled.img`
  object-fit: none;
`

export const ProductBrand = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.33;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);
  padding-top: 11px;
  margin-bottom: 6px;
`

export const ProductName = styled.div`
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 2.8rem;
    line-height: 1.35;
    color: var(--highlight-blue);
  }
`

export const ProductDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.35;
  color: var(--content-background-dark-blue);
  padding-top: 14px;
`

export const additionalCtaStyles = `
    padding-bottom: 36px;
  }
`
