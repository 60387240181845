import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import * as Styled from './styles/Disclaimer.styles'
import { RegionContainer } from '../styles/Utility.styles'

const Disclaimer = ({ disclaimer }) => (
  <RegionContainer>
    <Styled.DisclaimerText>
      <PrismicRichText field={disclaimer.richText} />
    </Styled.DisclaimerText>
  </RegionContainer>
)

export default Disclaimer
