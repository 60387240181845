import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'

export const DisclaimerText = styled.div`
  grid-column: 2 / 3;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4;
  color: var(--content-background-dark-blue);
  padding-bottom: 43px;

  @media ${DEVICE.tabletL} {
    font-size: 1.8rem;
    line-height: 1.6;
  }
`
