import React from 'react'
import { object } from 'prop-types'
import { PrismicRichText } from '@prismicio/react'

import CtaLink from './CtaLink'
import * as Styled from './styles/ProductItem.styles'
import { Link } from 'gatsby'

const ProductItem = ({
  item: {
    product_link,
    product_image,
    product_company,
    product_name,
    product_description,
  },
}) => (
  <Link
    to={product_link.url}
    target="_blank"
    style={{ textDecorationLine: 'none' }}
  >
    <Styled.ProductRegion>
      <Styled.ProductImageContainer>
        <Styled.ProductImage src={product_image.url} alt={product_image.alt} />
      </Styled.ProductImageContainer>
      <Styled.ProductBrand>
        {product_company.document.data.product_company}
      </Styled.ProductBrand>
      <Styled.ProductName>
        <PrismicRichText field={product_name.richText} />
      </Styled.ProductName>
      <Styled.ProductDescription>
        <PrismicRichText field={product_description.richText} />
      </Styled.ProductDescription>
      <CtaLink
        text="Learn More About This Product"
        link={product_link}
        additionalStyles={Styled.additionalCtaStyles}
      />
    </Styled.ProductRegion>
  </Link>
)

ProductItem.propTypes = {
  item: object,
}

export default ProductItem
